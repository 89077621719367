body {
    margin: 0
}

.not-found {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    height: 100vh;
    justify-content: center;
    text-align: center;
}

.next-error-h1 {
    border-right: 1px solid rgba(0, 0, 0, .3);
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    line-height: 49px;
    margin: 0 20px 0 0;
    padding: 0 23px 0 0;
    vertical-align: top;
}

.next-error-message {
    display: inline-block;
}

.next-error-message h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 49px;
    margin: 0;
}

/*@media (prefers-color-scheme: dark) {
    body {
        color: #fff;
        background: #000
    }

    .next-error-h1 {
        border-right: 1px solid rgba(255, 255, 255, .3)
    }
}*/
